html {
  font-family: 'Varela Round', sans-serif;
}

html, body, #root {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  overscroll-behavior: contain;
}
@media (max-width: 800px) {
  html, body, #root  {
    /* min-height: calc(var(--vvh) * 100); */
    height: calc(var(--vvh) * 100);
    min-height: 0;
    overflow: hidden;
  }
}

/* Disable text selection on iOS */
@supports (-webkit-touch-callout: none) {
  h1, h2, h3, h4, h5, span, p {
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
}

link[rel="manifest"] {
  --pwacompat-splash-font: 0px Verdana;
}

h1 {
  font-size: 1.8em;
}

table {
  border-collapse: collapse;
}



.firebase-emulator-warning {
  display: none !important;
}
